import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { t, t2 } from '@/@core/libs/i18n/utils'

export default function tableMember() {
  // Use toast
  const toast = useToast()

  const refMemberTable = ref(null)

  const msgList = {
    errorfetch: t2('Error fetching {module} list', { module: t('Member') }),
    name: `${t('Prefix')} ${t('Name')} ${t('Lastname')} » ${t('ID')}`,
    mobile: `${t('Mobile')} » ${t('Email')} » ${t('Occupation')} » ${t('Contact Time')}`,
    line: `${t('LINE')}`,
    status: `${t('Status')} » ${t('Seller')} » ${t('Buyer')}`,
    actions: t('Actions'),
  }

  // Table Handlers
  const tableColumns = [
    { key: 'name', sortable: true, label: msgList.name },
    { key: 'mobile', sortable: false, label: msgList.mobile },
    { key: 'line', sortable: false, label: msgList.line },
    { key: 'status', sortable: false, label: msgList.status },
    { key: 'actions', label: msgList.actions },
  ]

  const perPage = ref(10)
  const totalMembers = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [2, 5, 10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('name_lastname')
  const isSortDirDesc = ref(false)
  const locale = ref('th')

  const dataMeta = computed(() => {
    const localItemsCount = refMemberTable.value ? refMemberTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalMembers.value,
    }
  })

  const refetchData = () => {
    refMemberTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, locale], () => {
    refetchData()
  })

  const getListMember = (ctx, callback) => {
    const params = {
      q: searchQuery.value,
      perPage: perPage.value,
      page: currentPage.value,
      sortBy: sortBy.value,
      sortDesc: isSortDirDesc.value,
      language: locale.value,
    }
    store
      .dispatch('store-member/getListMember', params)
      .then(response => {
        const { list, total } = response.data
        callback(list)
        totalMembers.value = total
        localStorage.setItem('filterMember', JSON.stringify(params))
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: msgList.errorfetch,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        },
        {
          position: 'bottom-right',
        })
      })
  }

  const isStatusVariant = isstatus => {
    if (isstatus === 'active') return 'success'
    if (isstatus === 'inactive') return 'danger'
    if (isstatus === 'pending') return 'warning'
    return 'danger'
  }

  const isStatusToText = isstatus => {
    if (isstatus === 'active') return 'Active'
    if (isstatus === 'inactive') return 'Inactive'
    if (isstatus === 'pending') return 'Pending'
    return ''
  }
  const textFirstUpper = text => text.slice(0, 1).toUpperCase() + text.slice(1, text.length)

  const isVariant = is => {
    if (is === 1) return 'success'
    if (is === 0) return 'danger'
    return 'danger'
  }

  const isToText = is => {
    if (is === 1) return 'Yes'
    if (is === 0) return 'No'
    return 'No'
  }

  return {
    getListMember,
    tableColumns,
    perPage,
    currentPage,
    totalMembers,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    locale,
    refMemberTable,

    refetchData,
    isStatusVariant,
    isStatusToText,
    textFirstUpper,
    isVariant,
    isToText,
  }
}
