import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    getDefaultMember(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/crm/get_default_member', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getListMember(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/crm/get_list_member', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getListMemberSelect(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/crm/get_list_member_select', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getMember(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/crm/get_member', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addMember(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/crm/add_member', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    editMember(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/crm/edit_member', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteMember(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/crm/delete_member', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    memberOptions(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/crm/member_options', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
